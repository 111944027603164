























































































































import Footer from "@monorepo/uikit/src/components/tableViews/Footer.vue";
import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

export default defineComponent({
  name: "LoginView",
  components: {
    Footer,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      isPasswordExpiration: false,
      isCodeError: false,
      isShowPassword: false,
      isEmailReset: false,
      isSecondAuthStep: false,
      password: "",
      email: "",
      emailResetText: "",
      authCode: "",
      timerSeconds: 0,
      isShowTimer: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isTwoStepsAuth"]),
    title() {
      return this.isEmailReset ? "Сброс пароля" : "Вход в личный кабинет";
    },
    btnTitle() {
      return this.isEmailReset ? "Отправить" : "Войти";
    },
    imgPath(): string {
      return `${environmentVariables.BASE_URL}assets/images/navigation/logo-blue.svg`;
    },
  },
  methods: {
    ...mapActions("app", ["sendChangePasswordEmail"]),
    ...mapActions("auth", ["auth", "recreateOtpCode", "getOtpExpirationTime"]),
    ...mapActions("auth", ["getCommonUserSettingsValues"]),
    async startTimer() {
      const seconds = await this.getOtpExpirationTime();
      if (seconds) {
        this.timerSeconds = !Number.isNaN(Number(seconds)) ? Number(seconds) : 0;
        this.isShowTimer = true;
        const timerId = setInterval(() => {
          if (this.timerSeconds === 0) {
            clearInterval(timerId);
            this.isShowTimer = false;
          }
          this.timerSeconds--;
        }, 1000);
      }
    },
    goToArchiveBaseUrl() {
      window.open("https://archives.gov.ru/", "_blank");
    },
    goToBaseUrl() {
      window.open("https://digital.gov.ru/", "_blank");
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    reset() {
      this.isError = false;
      this.isPasswordExpiration = false;
      this.isCodeError = false;
      this.password = "";
      this.email = "";
      this.emailResetText = "";
      this.isEmailReset = false;
      this.isSecondAuthStep = false;
    },
    async recreateAuthCode() {
      if (this.isShowTimer) {
        return;
      }
      const isSend = await this.recreateOtpCode();
      if (isSend) {
        this.isCodeError = false;
        this.authCode = "";
        await this.startTimer();
      }
    },
    onEmailReset() {
      this.isEmailReset = true;
      this.isSecondAuthStep = false;
      this.isError = false;
    },
    goToSavedLink() {
      try {
        const linkTo = localStorage.getItem("link");
        if (linkTo && !linkTo.startsWith("/reset-password")) {
          return this.$router.push(linkTo).finally(() => {
            localStorage.removeItem("link");
            this.isLoading = false;
          });
        }
        this.$router.push("/").finally(() => {
          this.isLoading = false;
        });
      } catch (e) {
        this.$router.push("/").finally(() => {
          this.isLoading = false;
        });
      }
    },
    onAuth() {
      this.isEmailReset = false;
      this.isSecondAuthStep = false;
      this.timerSeconds = 0;
      this.isShowTimer = false;
      this.isError = false;
      this.isCodeError = false;
      this.isPasswordExpiration = false;
    },
    async secondAuthStep() {
      if (!this.authCode.trim()) {
        this.isLoading = false;
        return;
      }
      const { email: username, password, authCode } = this;
      const { isLogin } = await this.auth({ username, password, authCode, isSecondStepAuth: true });

      if (isLogin) {
        await this.getCommonUserSettingsValues();
        await this.goToSavedLink();
        this.isSecondAuthStep = false;
        this.timerSeconds = 0;
        this.isShowTimer = false;
      } else {
        this.isCodeError = true;
      }
      this.isLoading = false;
    },
    async emailReset() {
      const isSend = await this.sendChangePasswordEmail(this.emailResetText);
      if (isSend) {
        this.reset();
      }
      this.isLoading = false;
    },
    checkAuthStepsNumber() {
      if (this.isTwoStepsAuth) {
        this.isError = false;
        this.isSecondAuthStep = true;
        this.startTimer();
      } else {
        this.getCommonUserSettingsValues();
        this.goToSavedLink();
      }
    },
    async onSubmit(e: Event) {
      e.preventDefault();
      this.isLoading = true;

      if (this.isEmailReset || this.isSecondAuthStep) {
        this.isEmailReset ? await this.emailReset() : await this.secondAuthStep();
        return;
      }

      const { email: username, password } = this;
      const { isLogin, errorCode } = await this.auth({ username, password });

      if (isLogin) {
        this.checkAuthStepsNumber();
      } else if (errorCode === -30) {
        this.isPasswordExpiration = true;
      } else {
        this.isError = true;
      }

      this.isLoading = false;
    },
  },
});
